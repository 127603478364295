import React from 'react';
import ReportingApiService from 'services/ReportingApiService';

export const ReportingApiContext = React.createContext(new ReportingApiService());

export function useReportingApi(): ReportingApiService {
  return React.useContext(ReportingApiContext);
}

export default function ReportingApiProvider({ children }: { children: React.ReactNode }): JSX.Element {
  return <ReportingApiContext.Provider value={new ReportingApiService()}>{children}</ReportingApiContext.Provider>;
}
