import { client } from 'http_client';

export default class LocalizationService {
  async getLocalization(type: string, locale: string) {
    const path = `/localization/${type}/${locale}.json`;
    const response = await client.fetchWithTokenRefresh(path, {
      credentials: 'include',
    });

    if (response.ok) {
      return await response.json();
    } else {
      return {
        localeCode: 'en-US',
        translations: {},
      };
    }
  }
}
