// Do not edit this file, it is automatically generated by @paprika/build-translations
    const locales = {
  "pt": {
    "translation": {
      "timePicker": {
        "am": "AM",
        "custom": "Personalizado",
        "hours": "Horas",
        "minutes": "Minutos",
        "period": "Período",
        "pm": "PM"
      },
      "back": "Voltar",
      "remove": "remover",
      "remove_a11y": "Remover {{value}}",
      "moreInformation": "mais informações",
      "close": "Fechar",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. Você está na linha {{rowIndex}}, coluna {{columnIndex}}. Ignorar as seguintes informações:"
      },
      "uploader": {
        "drop_files": "Solte os arquivos para carregar",
        "drop_files_here_or": "Solte os arquivos para carregar aqui ou",
        "choose_from_computer": "escolha no seu computador",
        "choose_from_computer_a11y": "Escolher arquivos no seu computador",
        "cancel_upload": "Cancelar upload de {{name}}",
        "restart_upload": "Selecione {{name}} novamente para tentar outra vez",
        "file_list": "Lista de arquivos",
        "errors": {
          "size": "O arquivo deve ser menor que {{maxFileSize}}",
          "type": "O arquivo precisa ser de um dos seguintes tipos: {{- supportedTypes}}"
        },
        "status": {
          "idle": "Ocioso",
          "processing": "Processando",
          "uploading": "Fazendo upload",
          "success": "Sucesso",
          "cancel": "Cancelar",
          "error": "Erro"
        },
        "progress": {
          "cancelled": "Cancelado(a)",
          "complete": "Concluído(a)",
          "error": "Ocorreu um erro para {{name}}: {{error}}",
          "idle": "Ocioso",
          "file_progress": "{{name}} está {{progress}}",
          "uploading_percent": "Carregando, {{a11yProgress}}%",
          "uploading": "Carregando {{progressWithUnits}} de {{sizeWithUnits}}"
        },
        "size_abbreviations": {
          "byte": "B",
          "kibibyte": "KiB",
          "mebibyte": "MiB",
          "gibibyte": "GiB",
          "tebibyte": "TiB"
        }
      },
      "actions": {
        "delete": "Excluir",
        "apply": "Aplicar",
        "cancel": "Cancelar",
        "confirm": "Salvar",
        "decline": "Recusar"
      },
      "datePicker": {
        "confirmation_format": "ll"
      },
      "calendar": {
        "header_format": "MMMM YYYY",
        "month": "Mês",
        "year": "Ano"
      },
      "daterange": {
        "last_7_days": "Últimos 7 Dias",
        "last_30_days": "Últimos 30 Dias",
        "this_month": "Este Mês",
        "last_month": "Mês Passado",
        "start_date": "Data de Início",
        "end_date": "Data de Término"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} abre em uma nova guia."
      },
      "comment": {
        "delete": "Excluir comentário"
      },
      "pagination": {
        "intro": "Paginação, atualmente na página {{currentPage}}",
        "page": "Página {{pageNumber}}",
        "ellipsis": "Mais páginas",
        "prev": "Página anterior",
        "next": "Próxima página"
      },
      "formElement": {
        "optional": "(opcional)",
        "required": "(obrigatório)",
        "aria_info_circle": "Informação"
      },
      "prevNextArrowButton": {
        "arrowup": "para cima",
        "arrowdown": "para baixo",
        "escape": "ESC"
      },
      "collapsible": {
        "ariaText": "Seção recolhível",
        "collapse": "Ocultar",
        "expand": "Expandir"
      },
      "sortable": {
        "aria_description": "Item arrastável. Pressione espaço para levantar.",
        "aria_lift": "Você levantou um item em posição {{source}}. Use as teclas de seta para mover, espaço para soltar e esc para cancelar.",
        "aria_moving": "Você moveu o item da posição {{source}} para a posição {{destination}}.",
        "aria_moving_outside": "Você atualmente não está arrastando sobr euma área onde é possível soltar.",
        "aria_cancelled": "Movimento cancelado. O item voltou para sua posição inicial de {{source}}.",
        "aria_dropped": "Você soltou o item. Ele foi movido da posição {{source}} para a posição {{destination}}.",
        "aria_dropped_outside": "O item foi solto enquanto não estava sobre a lista. O item voltou para sua posição inicial de {{source}}.",
        "aria_remove": "Remover"
      },
      "progressAccordion": {
        "active": "ativo(a)",
        "no_response": "Nenhum"
      },
      "collapsibleText": {
        "more": "Mostrar mais",
        "less": "Mostrar menos",
        "more_about": "mostrar mais sobre {{topic}}",
        "less_about": "mostrar menos sobre {{topic}}"
      },
      "listBox": {
        "trigger": {
          "clear_selection": "Limpar seleção",
          "placeholder": "Selecione..."
        },
        "filter": {
          "a11y_text": "Pesquisar",
          "no_results_message": "Nenhum resultado encontrado",
          "placeholder": "Pesquisar..."
        },
        "footer": {
          "accept": "Aplicar",
          "cancel": "Cancelar",
          "clear": "Limpar"
        }
      },
      "listBoxBrowser": {
        "explore": "Explorar mais opções",
        "selected": "opções selecionadas",
        "selectOne": "Selecione um ou mais itens..."
      },
      "listBoxWithTags": {
        "placeholder": "Selecione...",
        "no_results_found": "Nenhum resultado encontrado",
        "all_items_have_been_selected": "Todos os itens foram selecionados",
        "a11y_text_trigger": "Opção de seleção múltipla: {{options}}"
      },
      "search": {
        "searchTerm": "Pesquisar termo..."
      },
      "actionBar": {
        "columns_arrangement": {
          "hide_all": "Ocultar tudo",
          "show_all": "Mostrar tudo",
          "label": "Organizar",
          "singular_label": "1 coluna oculta",
          "plural_label": "{{numberOfHiddenColumn}} colunas ocultas",
          "search_placeholder": "Pesquisar..."
        },
        "no_results": "Nenhum resultado",
        "search_placeholder": "Pesquisar...",
        "search_a11y_text": "Pesquisar os dados",
        "sort": {
          "no_sorts_applied": "Nenhum filtro aplicado a esta exibição",
          "label": "Ordenar",
          "singular_label": "1 coluna ordenada",
          "plural_label": "{{numberOfFields}} colunas ordenadas",
          "add_field": "Adicionar um campo para ordenar",
          "sort_by": "Ordenar por",
          "then_by": "depois por",
          "rules": {
            "ascending": {
              "boolean": "Ordem crescente (verdadeiro → falso)",
              "number": "Ordem crescente (1 → 9)",
              "text": "Ordem crescente (A → Z)",
              "date": "Ordem crescente (1 → 9)",
              "single_select": "Ordem crescente (A → Z)"
            },
            "descending": {
              "boolean": "Ordem decrescente (falso → verdadeiro)",
              "number": "Ordem decrescente (9 → 1)",
              "text": "Ordem decrescente (Z → A)",
              "date": "Ordem decrescente (9 → 1)",
              "single_select": "Ordem decrescente (Z → A)"
            }
          }
        }
      },
      "breadcrumbs": {
        "aria_label": "Navegação estrutural",
        "aria_expand": "Mostrar todos os itens de navegação estrutural.",
        "aria_back_to": "Voltar para"
      },
      "sideNavigation": {
        "aria_label": "Navegação lateral",
        "trigger_aria_label": "Abrir navegação"
      },
      "copyInput": {
        "clicked_tooltip": "Copiado",
        "hover_tooltip": "Copiar para área de transferência"
      },
      "dynamicHyperlinkTransformer": {
        "access_denied": "Acesso negado",
        "invalid_url": "URL inválido",
        "exceeded_max_links_rendered": "Não foi possível renderizar",
        "loading": "Carregando..."
      },
      "filter": {
        "actions": {
          "add": "Adicionar filtro",
          "clear": "Limpar",
          "delete": "Excluir"
        },
        "no_filters_applied": "Nenhum filtro aplicado a esta exibição",
        "label": "Filtro",
        "label_with_count": "{{count}} filtrado(s)",
        "and": "E",
        "or": "Ou",
        "rules": {
          "is": "é",
          "is_not": "não é",
          "contains": "contém",
          "does_not_contain": "não contém",
          "is_blank": "está em branco",
          "is_not_blank": "não está em branco",
          "equals": "igual a",
          "not_equal_to": "diferente de",
          "greater_than": ">",
          "less_than": "<",
          "greater_than_or_equal_to": "≥",
          "less_than_or_equal_to": "≤",
          "is_empty": "está vazio",
          "is_not_empty": "não está vazio",
          "is_before": "é antes de",
          "is_after": "é depois de",
          "is_one_of": "é um(a) de",
          "is_not_one_of": "não é um(a) de",
          "true": "verdadeiro",
          "false": "falso"
        }
      },
      "input": {
        "clear_button": {
          "aria_label": "Limpar entrada"
        }
      }
    }
  }
};
    export default locales;