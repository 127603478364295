import React from "react";

function SvgSearch({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.575 15.516l-2.88-2.88A6.897 6.897 0 008.9 2 6.896 6.896 0 002 8.9a6.896 6.896 0 0010.638 5.795l2.879 2.878a1.456 1.456 0 002.058-2.056zM8.8 3.6c2.872 0 5.2 2.325 5.2 5.198a5.16 5.16 0 01-1.524 3.676 5.163 5.163 0 01-3.677 1.523A5.197 5.197 0 013.6 8.798 5.196 5.196 0 018.8 3.6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSearch;
