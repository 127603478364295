import React from 'react';
import Button from '@paprika/button';
import Card from '@paprika/card';
import NotificationCard from '@paprika/notification-card';
import BubblyError from '@acl-services/wasabicons/lib/BubblyError';
import config from 'config';

import './NoAccess.scss';

function NoAccess(): JSX.Element {
  return (
    <Card className="no-access-card">
      <NotificationCard className="no-access">
        <NotificationCard.Image align="center">
          <BubblyError />
        </NotificationCard.Image>
        <NotificationCard.Header level={2}>You Do Not Have Access</NotificationCard.Header>
        <NotificationCard.Body className="description">
          We are sorry, you do not have the permission to access this page. This page is in Beta and is limited to
          specific users.
        </NotificationCard.Body>
        <Button.Link kind={Button.types.kind.PRIMARY} href={config.launchpadUrl}>
          Back to Launchpad
        </Button.Link>
      </NotificationCard>
    </Card>
  );
}

export default NoAccess;
