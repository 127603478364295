import React from 'react';
import PageHeader from '@acl-services/sriracha-page-header';
import Tag from '@paprika/tag';
import ActivityCenterIcon from '@acl-services/wasabicons/lib/ActivityCenter';

import './MainPageHeader.scss';

function MainPageHeader(): JSX.Element {
  return (
    <PageHeader>
      <PageHeader.Icon>
        <div className="icon">
          <ActivityCenterIcon aria-label="Activity Centers" />
        </div>
      </PageHeader.Icon>

      <PageHeader.Title>
        <span className="title">
          Activity Centers&nbsp;
          <span>
            <Tag theme={Tag.types.theme.LIGHT_BLUE}>Beta</Tag>
          </span>
        </span>
      </PageHeader.Title>
    </PageHeader>
  );
}

export default MainPageHeader;
