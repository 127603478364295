import React from 'react';
import ReactDOM from 'react-dom';
import config from 'config';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  const pendoScript = document.createElement('script');
  pendoScript.type = 'text/javascript';
  pendoScript.crossOrigin = 'use-credentials';
  pendoScript.src = config.pendoMonitorScript;
  document.head.appendChild(pendoScript);
}

const render = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}
