import { client } from 'http_client';
import config from 'config';

export default class ReportingApiService {
  async getSession() {
    const response = await client.fetchWithTokenRefresh(`${config.reportingApiUrl}/session`, {
      credentials: 'include',
    });
    if (response.ok) {
      return await response.json();
    } else {
      // TODO:
    }
  }
  async getDashboardUrl(type: string) {
    const response = await client.fetchWithTokenRefresh(`${config.reportingApiUrl}/dashboard?type=${type}`, {
      credentials: 'include',
    });
    if (response.ok) {
      return await response.json();
    } else {
      // TODO:
    }
  }
}
