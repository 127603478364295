import React from 'react';
import LaunchpadApiService from 'services/LaunchpadApiService';

export const LaunchpadApiContext = React.createContext(new LaunchpadApiService());

export function useLaunchpadApi(): LaunchpadApiService {
  return React.useContext(LaunchpadApiContext);
}

export default function LaunchpadApiProvider({ children }: { children: React.ReactNode }): JSX.Element {
  return <LaunchpadApiContext.Provider value={new LaunchpadApiService()}>{children}</LaunchpadApiContext.Provider>;
}
