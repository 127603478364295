import React from "react";

function SvgTimes({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path d="M3 4.4L4.4 3 17 15.6 15.6 17 3 4.4z" fill="currentColor" />
      <path d="M15.6 3L17 4.4 4.4 17 3 15.6 15.6 3z" fill="currentColor" />
    </svg>
  );
}

export default SvgTimes;
