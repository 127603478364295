// Do not edit this file, it is automatically generated by @paprika/build-translations
    const locales = {
  "de": {
    "translation": {
      "timePicker": {
        "am": "AM",
        "custom": "Angepasst",
        "hours": "Stunden",
        "minutes": "Minuten",
        "period": "Zeitraum",
        "pm": "PM"
      },
      "back": "Zurück",
      "remove": "entfernen",
      "remove_a11y": "{{value}} entfernen",
      "moreInformation": "weitere Informationen",
      "close": "Schließen",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. Sie befinden sich in Reihe {{rowIndex}}, Spalte {{columnIndex}}. Ignorieren Sie die folgenden Informationen:"
      },
      "uploader": {
        "drop_files": "Dateien zum Hochladen ablegen",
        "drop_files_here_or": "Legen Sie Dateien zum Hochladen hier ab oder",
        "choose_from_computer": "wählen Sie sie auf Ihrem Computer aus",
        "choose_from_computer_a11y": "Wählen Sie Dateien auf Ihrem Computer aus",
        "cancel_upload": "Upload für {{name}} abbrechen",
        "restart_upload": "Wählen Sie {{name}} erneut, um es erneut zu versuchen",
        "file_list": "Liste der Dateien",
        "errors": {
          "size": "Datei muss kleiner als {{maxFileSize}} sein",
          "type": "Datei muss die Datei einen der folgenden Dateitypen aufweisen: {{- supportedTypes}}"
        },
        "status": {
          "idle": "Inaktiv",
          "processing": "In Bearbeitung",
          "uploading": "Wird hochgeladen",
          "success": "Erfolg",
          "cancel": "Abbrechen",
          "error": "Fehler"
        },
        "progress": {
          "cancelled": "Abgebrochen",
          "complete": "Abgeschlossen",
          "error": "Für {{name}} ist ein Fehler aufgetreten: {{error}}",
          "idle": "Inaktiv",
          "file_progress": "{{name}} ist {{progress}}",
          "uploading_percent": "Daten werden hochgeladen, {{a11yProgress}}%",
          "uploading": "{{progressWithUnits}} von {{sizeWithUnits}} werden hochgeladen"
        },
        "size_abbreviations": {
          "byte": "B",
          "kibibyte": "KiB",
          "mebibyte": "MiB",
          "gibibyte": "GiB",
          "tebibyte": "TiB"
        }
      },
      "actions": {
        "delete": "Löschen",
        "apply": "Anwenden",
        "cancel": "Abbrechen",
        "confirm": "Speichern",
        "decline": "Ablehnen"
      },
      "datePicker": {
        "confirmation_format": "DD. MMMM YYYY"
      },
      "calendar": {
        "header_format": "MMMM YYYY",
        "month": "Monat",
        "year": "Jahr"
      },
      "daterange": {
        "last_7_days": "Letzte 7 Tage",
        "last_30_days": "Letzte 30 Tage",
        "this_month": "Diesen Monat",
        "last_month": "Letzten Monat",
        "start_date": "Anfangsdatum",
        "end_date": "Enddatum"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} wird auf einer neuen Registerkarte geöffnet."
      },
      "comment": {
        "delete": "Anmerkung löschen"
      },
      "pagination": {
        "intro": "Seitennummerierung, aktuell auf Seite {{currentPage}}",
        "page": "Seite {{pageNumber}}",
        "ellipsis": "Weitere Seiten",
        "prev": "Vorherige Seite",
        "next": "Nächste Seite"
      },
      "formElement": {
        "optional": "(optional)",
        "required": "(erforderlich)",
        "aria_info_circle": "Informationen"
      },
      "prevNextArrowButton": {
        "arrowup": "Auf",
        "arrowdown": "Ab",
        "escape": "Escape"
      },
      "collapsible": {
        "ariaText": "Einklappbarer Abschnitt",
        "collapse": "Reduzieren",
        "expand": "Erweitern"
      },
      "sortable": {
        "aria_description": "Ziehbares Element. Drücken Sie zum Anheben die Leertaste.",
        "aria_lift": "Sie haben ein Element in Position {{source}} angehoben. Verwenden Sie die Pfeiltasten, um es zu bewegen, die Leertaste, um es fallen zu lassen, und Esc, um Aktion abzubrechen.",
        "aria_moving": "Sie haben das Element von Position {{source}} an Position {{destination}} verschoben.",
        "aria_moving_outside": "Sie ziehen das Element derzeit nicht über einen Bereich, auf dem Sie es ablegen können.",
        "aria_cancelled": "Verschieben abgebrochen. Das Element ist in seine Ausgangsposition von {{source}} zurückgekehrt.",
        "aria_dropped": "Sie haben das Element abgelegt. Das Element wurde von Position {{source}} an Position {{destination}} verschoben.",
        "aria_dropped_outside": "Das Element wurde abgelegt, während es sich nicht über der Liste befand. Das Element ist in seine Ausgangsposition von {{source}} zurückgekehrt.",
        "aria_remove": "Entfernen"
      },
      "progressAccordion": {
        "active": "aktiv",
        "no_response": "Keine"
      },
      "collapsibleText": {
        "more": "Mehr anzeigen",
        "less": "Weniger anzeigen",
        "more_about": "Mehr über {{topic}} anzeigen",
        "less_about": "Weniger über {{topic}} anzeigen"
      },
      "listBox": {
        "trigger": {
          "clear_selection": "Auswahl entfernen",
          "placeholder": "Auswählen..."
        },
        "filter": {
          "a11y_text": "Suchen",
          "no_results_message": "Keine Ergebnisse gefunden",
          "placeholder": "Suchen..."
        },
        "footer": {
          "accept": "Anwenden",
          "cancel": "Abbrechen",
          "clear": "Löschen"
        }
      },
      "listBoxBrowser": {
        "explore": "Weitere Optionen anzeigen",
        "selected": "ausgewählte Optionen",
        "selectOne": "Ein oder mehrere Elemente auswählen..."
      },
      "listBoxWithTags": {
        "placeholder": "Auswählen…",
        "no_results_found": "Keine Ergebnisse gefunden",
        "all_items_have_been_selected": "Alle Elemente wurden ausgewählt",
        "a11y_text_trigger": "Option zur Mehrfachauswahl: {{options}}"
      },
      "search": {
        "searchTerm": "Suchbegriff..."
      },
      "actionBar": {
        "columns_arrangement": {
          "hide_all": "Alles ausblenden",
          "show_all": "Alles anzeigen",
          "label": "Neu anordnen",
          "singular_label": "1 Spalte ausgeblendet",
          "plural_label": "{{numberOfHiddenColumn}} Spalten ausgeblendet",
          "search_placeholder": "Suchen..."
        },
        "no_results": "Keine Ergebnisse",
        "search_placeholder": "Suchen...",
        "search_a11y_text": "Daten durchsuchen",
        "sort": {
          "no_sorts_applied": "Auf diese Ansicht wurde keine Sortierung angewendet",
          "label": "Sortieren",
          "singular_label": "1 Spalte sortiert",
          "plural_label": "{{numberOfFields}} Spalten sortiert",
          "add_field": "Feld hinzufügen zum Sortieren nach",
          "sort_by": "Sortieren nach",
          "then_by": "dann nach",
          "rules": {
            "ascending": {
              "boolean": "Aufsteigend sortieren (wahr → falsch)",
              "number": "Aufsteigend sortieren (1 → 9)",
              "text": "Aufsteigend sortieren (A → Z)",
              "date": "Aufsteigend sortieren (1 → 9)",
              "single_select": "Aufsteigend sortieren (A → Z)"
            },
            "descending": {
              "boolean": "Absteigend sortieren (falsch → wahr)",
              "number": "Absteigend sortieren (9 → 1)",
              "text": "Absteigend sortieren (Z → A)",
              "date": "Absteigend sortieren (9 → 1)",
              "single_select": "Absteigend sortieren (Z → A)"
            }
          }
        }
      },
      "breadcrumbs": {
        "aria_label": "Breadcrumbs",
        "aria_expand": "Sie können alle Breadcrumb-Elemente anzeigen.",
        "aria_back_to": "Zurück zu"
      },
      "sideNavigation": {
        "aria_label": "Seiten-Navigation",
        "trigger_aria_label": "Navigation öffnen"
      },
      "copyInput": {
        "clicked_tooltip": "Kopiert",
        "hover_tooltip": "In die Zwischenablage kopieren"
      },
      "dynamicHyperlinkTransformer": {
        "access_denied": "Zugriff verweigert",
        "invalid_url": "Ungültige URL",
        "exceeded_max_links_rendered": "Rendern nicht möglich",
        "loading": "Wird geladen..."
      },
      "filter": {
        "actions": {
          "add": "Filter hinzufügen",
          "clear": "Löschen",
          "delete": "Löschen"
        },
        "no_filters_applied": "Auf diese Ansicht wurden keine Filter angewendet",
        "label": "Filter",
        "label_with_count": "{{count}} gefiltert",
        "and": "Und",
        "or": "Oder",
        "rules": {
          "is": "ist",
          "is_not": "ist nicht",
          "contains": "enthält",
          "does_not_contain": "enthält nicht",
          "is_blank": "bleibt leer",
          "is_not_blank": "bleibt nicht leer",
          "equals": "gleich",
          "not_equal_to": "ungleich",
          "greater_than": ">",
          "less_than": "<",
          "greater_than_or_equal_to": "≥",
          "less_than_or_equal_to": "≤",
          "is_empty": "ist leer",
          "is_not_empty": "ist nicht leer",
          "is_before": "ist vor",
          "is_after": "ist nach",
          "is_one_of": "ist ein Element von",
          "is_not_one_of": "ist kein Element von",
          "true": "wahr",
          "false": "falsch"
        }
      },
      "input": {
        "clear_button": {
          "aria_label": "Transparente Eingabe"
        }
      }
    }
  }
};
    export default locales;