import React from 'react';
import Spinner from '@paprika/spinner';

import './PageSpinner.scss';

export default function PageSpinner(): JSX.Element {
  return (
    <div className="page-spinner">
      <Spinner />
    </div>
  );
}
