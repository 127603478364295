import React from 'react';
import './App.scss';
import GlobalNav from 'components/GlobalNav';
import MainPageHeader from 'components/MainPageHeader';
import Dashboard from 'components/Dashboard';

export default function App(): JSX.Element {
  return (
    <div className="layout">
      <GlobalNav />
      <MainPageHeader />
      <Dashboard type="issue_tracker" />
    </div>
  );
}
