import React from "react";

function SvgNewTab({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path d="M11.24 4H16v4.76h-1.36V6.32L10.361 10.6 9.4 9.639l4.279-4.28H11.24V4z" fill="currentColor" />
      <path
        d="M14.8 14.457c0 .852-.691 1.543-1.543 1.543H5.543A1.543 1.543 0 014 14.457V6.743C4 5.89 4.691 5.2 5.543 5.2h3.086v1.543H5.543v7.714h7.714v-3.086H14.8v3.086z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgNewTab;
