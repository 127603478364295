import React, { useRef } from 'react';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import ReportingApiProvider, { useReportingApi } from 'providers/ReportingApiProvider';
import LocalizationProvider, { useLocalization } from 'providers/LocalizationProvider';
import PageSpinner from 'components/common/PageSpinner';
import NoAccess from 'components/NoAccess';

function Dashboard({ type }) {
  const [dashboard, setDashboard] = React.useState<any>();
  const [dashboardLoaded, setDashboardLoaded] = React.useState<boolean>();
  const reportingApi = useReportingApi();
  const containerRef = useRef(null);
  const localizationService = useLocalization();

  function loadCallback() {
    setDashboardLoaded(true);
  }

  function parametersChangeCallback(...rest: any[]) {
    console.log(rest);
  }

  React.useEffect(() => {
    async function loadDashboard() {
      const [{ embedUrl }, session] = await Promise.all([
        reportingApi.getDashboardUrl(type),
        reportingApi.getSession(),
      ]);

      if (embedUrl) {
        const localization = await localizationService.getLocalization(type, session.orgLocale);

        const options = {
          url: embedUrl,
          container: containerRef.current,
          scrolling: 'no',
          undoRedoDisabled: false,
          resetDisabled: false,
          footerPaddingEnabled: false,
          loadCallback,
          parametersChangeCallback,
          locale: localization.localeCode,
          parameters: localization.translations,
        };
        const dashboard = embedDashboard(options);
        setDashboard(dashboard);
      } else {
        setDashboardLoaded(true);
      }
    }
    loadDashboard();
  }, [type, reportingApi]);

  return (
    <div id="embeddingContainer" ref={containerRef}>
      {!dashboardLoaded ? <PageSpinner /> : null}
      {dashboardLoaded && !dashboard ? <NoAccess /> : null}
    </div>
  );
}

export default function DashboardWithProvider({ type }): JSX.Element {
  return (
    <ReportingApiProvider>
      <LocalizationProvider>
        <Dashboard type={type} />
      </LocalizationProvider>
    </ReportingApiProvider>
  );
}
