import React from "react";

function SvgArrowRight({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M6.5 14.58L11.136 10 6.5 5.41 7.927 4l4.993 4.933a1.5 1.5 0 010 2.134L7.927 16 6.5 14.58z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgArrowRight;
