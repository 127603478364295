import React from "react";

function SvgInfoCircle({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4 14H7.6v-1.6h1.6V10h-.8V8.4H10a.8.8 0 01.8.8v3.2h1.6V14zM10 5.8a1 1 0 110 2 1 1 0 010-2zM10 2c-4.411 0-8 3.589-8 8 0 4.412 3.589 8 8 8s8-3.588 8-8c0-4.411-3.589-8-8-8z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgInfoCircle;
