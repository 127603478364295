import React from 'react';
import GlobalNavigation, {
  AppSwitcherSidePanel,
  CurrentApp,
  Helper,
  KeyboardShortcutPanel,
  LaunchpadLink,
  User,
} from '@acl-services/sriracha-global-navigation';
import ReportingApiProvider, { useReportingApi } from 'providers/ReportingApiProvider';
import LaunchpadApiProvider, { useLaunchpadApi } from 'providers/LaunchpadApiProvider';

const helpDocUrl = 'https://www.wegalvanize.com'; // TODO: replace when helpdocs are available

export function GlobalNav(): JSX.Element {
  const [navData, setNavData] = React.useState<any>();
  const reportingApi = useReportingApi();
  const launchpadApi = useLaunchpadApi();

  React.useEffect(() => {
    async function fetchGlobalNav() {
      const session = await reportingApi.getSession();
      if (session.orgId) {
        const navBarJson = await launchpadApi.getGlobalNavBar(session.orgId);
        setNavData(navBarJson.data);
      }
    }

    fetchGlobalNav();
  }, [reportingApi, launchpadApi]);

  const organizationName =
    navData !== undefined
      ? navData.appSwitcherProps.organizations.find(
          (org: { id: string }) => org.id === navData.appSwitcherProps.initialOrganizationId,
        ).name
      : '';

  return (
    <div className={`sriracha ${!navData ? 'loading' : ''}`}>
      {navData !== undefined ? (
        <GlobalNavigation
          isDiligentBrand={true}
          mainContentSelector="#main"
          currentOrgSubscriptionType={navData.currentOrgSubscriptionType}
          hasOrgNameOnTop={navData.hasOrgNameOnTop}
        >
          <LaunchpadLink orgName={organizationName} launchpadUrl={navData.links.launchpad} />

          <CurrentApp appModuleName="Activity Centers" appUrl={window.location.origin} />
          <Helper helpDocUrl={helpDocUrl} supportUrl={navData.links.support} />
          <User
            customerName={navData.customerName}
            username={navData.userFullName}
            userProfileUrl={navData.links.profile}
            logoutUrl={navData.links.logout}
          />

          <AppSwitcherSidePanel {...navData.appSwitcherProps} />
          <KeyboardShortcutPanel />
        </GlobalNavigation>
      ) : (
        <GlobalNavigation isDiligentBrand={true} mainContentSelector="#main">
          <CurrentApp appModuleName="Activity Centers" appUrl={window.location.origin} />
        </GlobalNavigation>
      )}
    </div>
  );
}

export default function GlobalNavWithProvider(): JSX.Element {
  return (
    <LaunchpadApiProvider>
      <ReportingApiProvider>
        <GlobalNav />
      </ReportingApiProvider>
    </LaunchpadApiProvider>
  );
}
