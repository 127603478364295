import React from "react";

function SvgArrowUp({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M5.41 13.5L10 8.864l4.59 4.636L16 12.073 11.067 7.08a1.5 1.5 0 00-2.134 0L4 12.073 5.41 13.5z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgArrowUp;
