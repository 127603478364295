const protocol = window.location.protocol;
const [, subdomain, domain, tld] = window.location.hostname.split('.');
const regionSuffix = subdomain.split('-').reverse()[0];
const region = regionSuffix.length === 2 && regionSuffix !== 'us' ? `-${regionSuffix}` : '';
const rootDomain = `${domain}.${tld}`;

const config = {
  launchpadUrl: `${protocol}//accounts.${rootDomain}`,
  loginUrl: `${protocol}//accounts.${rootDomain}/login`,
  tokenRefreshUrl: `${protocol}//accounts.${rootDomain}/api/token/refresh`,
  reportingApiUrl: `${protocol}//activity-center-api-lcl${region}.${rootDomain}`,
  launchpadApiUrl: `${protocol}//apis${region}.${rootDomain}/accounts/api`,
  pendoMonitorScript: `${protocol}//monitor${region}.${rootDomain}/v1/monitor.js`,
};

export default config;
